#login {
    background-image: url("//s3.eu-central-1.amazonaws.com/topsite-cms4-assets/img/bg_login.jpg");
    background-size: cover;
    background-position: 50% 50%;
    
    .logo {
        position: absolute;
        top: 10px; left: 50%;
        width: 120px;
        height: 32px;
        transform: translate(-60px, 0);
        background: rgba(0, 0, 0, 0) url("https://s3.eu-central-1.amazonaws.com/topsite-cms4-assets/img/logo_topsite.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% auto;
    }
    
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: 50% 50%;
    
    > .panel {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 300px;
    }
}

@media(min-width: $screen-md-min) {
    #login {
        .logo {
            left: 50px;
            transform: translate(0, 0);
        }
    }
}