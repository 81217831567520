aside#navigation {
    //position: absolute;
    //top: 50px; left: 0;
    float: left;
    width: $aside-width;
    min-height: calc(100vh - 50px);
    padding: 0;
    background: $aside-background;
    border-right: 1px solid shade($aside-background, 10%);
    
    .websites {
        padding: 30px 15px 15px 15px;
        border-bottom: 1px solid shade($aside-background, 10%);
        background: shade($aside-background, 5%);
        
        .dropdown {
            
            button {
                text-align: left;
                
                i {
                    font-size: 17px;
                }
                
                .flag-icon {
                    position: relative;
                    top: 3px;
                    font-size: 13px;
                }
            }
            
            .dropdown-menu {
                width: 100%;
                
                .dropdown-header {
                    clear: both;
                    color: #333;
                    font-size: 14px;
                    display: block;
                    font-weight: normal;
                    line-height: 1.42857;
                    padding: 3px 20px;
                    white-space: nowrap;
                }

                .dropdown-language {
                    color: #777;
                    font-size: 12px;

                    .flag-icon {
                        position: relative;
                        top: -1px;
                        margin-right: 5px;
                        font-size: 11px;
                    }
                }
            }
        }
    }
    
    nav {
        a {
            line-height: 20px;
            display: block;
            padding: 15px;
            text-decoration: none;
            color: #777;
            outline: none;
            border-right: 5px solid shade($aside-background, 10%);
            @include transition(background 140ms);
            
            &:hover {
                background: shade($aside-background, 10%);
                border-color: shade($aside-background, 20%);
            }
            
            .fa {
                margin-right: 10px;
                font-size: 17px;
                
                &.fa-angle-down {
                    float: right;
                    margin: 0;
                    font-size: 17px;
                }
            }
        }
        
        ul {
            padding: 0;
            list-style: none;
        }
        
        > ul {
            > li {
                border-bottom: 1px solid shade($aside-background, 10%);
                
                > a.active {
                    border-color: $brand-primary;
                    
                    > i:first-of-type {
                       color: $brand-primary;
                    }
                }
                
                ul li {
                    background: shade($aside-background, 3%);
                    border-top: 1px solid shade($aside-background, 10%);
                    
                    a {
                        padding: 7px 0 7px 50px;
                        
                        &.active {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}