.bootstrap-table {
    .search {
        margin: 0;
    }
    
    .table {
        tbody {
            tr {
                td {
                    padding-top: 9px;
                }
            }
        }
    }
}