.datepicker {
    padding: 4px;
    
    table tr th {
        font-weight: bold;
    }
    
    table tr td.active.active, 
    table tr td.active.highlighted.active, 
    table tr td.active.highlighted:active, 
    table tr td.active.highlighted:hover, 
    table tr td.active:active, 
    table tr td.active:hover {
        background-color: $brand-primary;
        border-color: #204d74;
        color: #fff;
    }
}