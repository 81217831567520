.notifications {
  position: fixed;
  z-index: 9999;
}

/* Positioning */ 
.notifications.top-right {
  right: 10px;
  top: 25px;
}

.notifications.top-left {
  left: 10px;
  top: 25px;
}

.notifications.bottom-left {
  left: 10px;
  bottom: 25px;
}

.notifications.bottom-right {
  right: 10px;
  bottom: 25px;
}

/* Notification Element */
.notifications > div {
  position: relative;
  margin: 5px 0px;
}

/**
 * bangTidy2 Style - Ported from Growl Style
 * Ported By Nijikokun @vizualover @nijikokun
 * Original Author Daryl Ginn
 * Based On http://dribbble.com/shots/527056-Growl-Theme-2
 *
 * To use, for style use: bangTidy
 *
 */
.alert-bangTidy {
  box-sizing: border-box;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: rgba(0, 0, 0, 0.80);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.88) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0, 0, 0, 0.80)), color-stop(100%,rgba(0, 0, 0, 0.88)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.80) 0%,rgba(0, 0, 0, 0.88) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.80) 0%,rgba(0, 0, 0, 0.88) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.80) 0%,rgba(0, 0, 0, 0.88) 100%);
  background: linear-gradient(top, rgba(0, 0, 0, 0.80) 0%,rgba(0, 0, 0, 0.88) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0, 0, 0, 0.80)', endColorstr='rgba(0, 0, 0, 0.88)',GradientType=0 );
  border: 1px solid #000;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.07), inset 0 0 0 1px rgba(255,255,255,0.1);
  -moz-box-shadow: inset 0 1px 0 rgba(255,255,255,0.07), inset 0 0 0 1px rgba(255,255,255,0.1);
  -o-box-shadow: inset 0 1px 0 rgba(255,255,255,0.07), inset 0 0 0 1px rgba(255,255,255,0.1);
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.07), inset 0 0 0 1px rgba(255,255,255,0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  color: white;
  -webkit-text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
  -moz-text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
  -o-text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
}

/**
 * alert-blackgloss.css v1.0
 * --
 * Based off blackgloss growl theme.
 * Copyright 2012 Nijiko Yonskai
 * --
 * Usage: 
 *   Set style to `blackgloss` and include this css.
 *
 * Note: 
 *   The gloss is only supported in webkit based browsers. 
 *   Chrome and Safari.
 */
@-webkit-keyframes notification {
	0% { -webkit-transform: rotateY(-90deg); opacity: 0; }
	70% { -webkit-transform: rotateY(20deg); opacity: .8; }
	90% { -webkit-transform: rotateY(-10deg); opacity: 1; }
	100% { -webkit-transform: rotateY(-0deg); opacity: 1; }
}

@-moz-keyframes notification {
	0% { -webkit-transform: rotateY(-90deg); opacity: 0; }
	70% { -webkit-transform: rotateY(20deg); opacity: .8; }
	90% { -webkit-transform: rotateY(-10deg); opacity: 1; }
	100% { -webkit-transform: rotateY(-0deg); opacity: 1; }
}

@-o-keyframes notification {
	0% { -webkit-transform: rotateY(-90deg); opacity: 0; }
	70% { -webkit-transform: rotateY(20deg); opacity: .8; }
	90% { -webkit-transform: rotateY(-10deg); opacity: 1; }
	100% { -webkit-transform: rotateY(-0deg); opacity: 1; }
}

@keyframes notification {
	0% { -webkit-transform: rotateY(-90deg); opacity: 0; }
	70% { -webkit-transform: rotateY(20deg); opacity: .8; }
	90% { -webkit-transform: rotateY(-10deg); opacity: 1; }
	100% { -webkit-transform: rotateY(-0deg); opacity: 1; }
}

/* Webkit Only */
.alert-blackgloss:before {
	background: -webkit-gradient(linear, 0% -16.5%, 16.5% -100%, from(rgba(255,255,255,.0)), to(rgba(255,255,255,.6)), color-stop(.99,rgba(255,255,255,.2)),color-stop(.5,rgba(255,255,255,.0))) no-repeat;
	-webkit-mask-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255,255,255,.5)), color-stop(.8,rgba(255,255,255,.0)));
	position: absolute;
	content: '.';
	line-height: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	overflow: hidden;
	text-indent: -99999px;
	-webkit-border-radius: 5px;
}

.alert-blackgloss {
	-webkit-animation: notification .75s linear;
	-moz-animation: notification .75s linear;
	-ms-animation: notification .75s linear;
	-o-animation: notification .75s linear;
	animation: notification .75s linear;
	background: rgba(0,0,0,1);
	-webkit-box-shadow: 0 2px 5px rgba(0,0,0,.5), inset 0 1px 0 rgba(255,255,255,.2), inset 0 0 0 1px rgba(255,255,255,.1);
	-moz-box-shadow: 0 2px 5px rgba(0,0,0,.5), inset 0 1px 0 rgba(255,255,255,.2), inset 0 0 0 1px rgba(255,255,255,.1);
	-ms-box-shadow: 0 2px 5px rgba(0,0,0,.5), inset 0 1px 0 rgba(255,255,255,.2), inset 0 0 0 1px rgba(255,255,255,.1);
	-o-box-shadow: 0 2px 5px rgba(0,0,0,.5), inset 0 1px 0 rgba(255,255,255,.2), inset 0 0 0 1px rgba(255,255,255,.1);
	box-shadow: 0 2px 5px rgba(0,0,0,.5), inset 0 1px 0 rgba(255,255,255,.2), inset 0 0 0 1px rgba(255,255,255,.1);
	border: 1px solid rgba(0,0,0,.95);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	border-radius: 5px;
	-webkit-font-smoothing: antialiased;
	text-shadow: 0 1px 2px rgba(0,0,0,.5);
	color: #fff;
	-webkit-transform: rotateY(-0deg);
	-moz-transform: rotateY(-0deg);
	-o-transform: rotateY(-0deg);
	transform: rotateY(-0deg);
	position: relative;
	background-clip: padding-box;
}

.alert-blackgloss .close {
  position: relative;
  top: -3px;
  right: -25px;
	color: #fff;
	content: 'x';
}