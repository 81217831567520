.nestable { 
	position: relative; 
	display: block; 
	margin: 15px 0 0 0; 
	padding: 0; 
	width: 100%;
	list-style: none; 
	line-height: 20px; 
}

.nestable-list { 
    display: block; 
    position: relative; 
    margin: 0; 
    padding: 0; 
    list-style: none;
    
    .nestable-list { 
        padding-left: 30px; 
    }
    
    .nestable-item,
    .nestable-empty,
    .nestable-placeholder { 
        display: block; 
        position: relative; 
        
        > button {
            position: absolute;
            top: 6px; left: 0;
            z-index: 1;
            text-decoration: none;
            color: #777;
        }
        
        .nestable-handle,
		.nestable-select,
        .nestable-nodrag { 
            display: block; 
            height: 40px;
            margin: 5px 0;
            padding: 8px 35px; 
            color: #333; 
            text-decoration: none; 
            border: 1px solid shade($aside-background, 10%);
            background: $aside-background;
            border-radius: $nav-pills-border-radius;
            
            &:hover {
                background: shade($aside-background, 3%);
            }
            
            &:hover > a, 
            a:hover { 
                 //color: #34495E;
            }
        }
        
		.nestable-handle { 
			cursor: move;
            
            &.disabled {
                cursor: default;
            }
            
            &.notvisible {
                background: shade($aside-background, 5%);
                border-color: shade($aside-background, 15%);
                
                &:hover {
                     background: shade($aside-background, 8%);
                }
            }
            
            &.inactive {
                background: $alert-danger-bg !important;
                border-color: $alert-danger-border !important;
                
                > a:first-child {
                    color: $alert-danger-text !important;
                }
                
                &:hover {
                     background: shade($alert-danger-bg, 3%) !important;
                }
            }
            
            &.new {
                background: $alert-success-bg;
                border-color: $alert-success-border;
                
                > a:first-child {
                    color: $alert-success-text;
                }
                
                &:hover {
                     background: shade($alert-success-bg, 3%);
                }
            }
		}
		
		.nestable-select { 
			cursor: pointer;
		}
		
		.nestable-nodrag { 
			cursor: default;
		}
        
        .nestable-options {
            position: absolute;
            top: 9px; right: 9px;
            
            a {
                float: left;
                margin-left: 3px;
            }
            
            .btn-link {
                color: #777;
                cursor: default;
            }
            
            .margin {
                margin-left: 10px;
            }
        }
    }
    
    > .nestable-item:first-child {
        > .nestable-handle,
		> .nestable-select,
        > .nestable-nodrag {
            margin-top: 0; 
        }
    }
    
    .nestable-item > .nestable-placeholder,
    .nestable-empty { 
        margin: 5px 0; 
        padding: 0; 
        min-height: 30px; 
        background: #f2fbff; 
        border: 1px dashed #b6bcbf; 
        box-sizing: border-box;
        -moz-box-sizing: border-box; 
    }
    
    .nestable-placeholder { 
        background: $state-info-bg;
        border: 1px solid $state-info-border;
        border-radius: $nav-pills-border-radius;
    }
    /*
    .nestable-empty {
        border: 1px dashed #bbb; 
        min-height: 100px; 
        background-color: #e5e5e5;
        background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), 
                          -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
        background-image:    -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), 
                             -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
        background-image:         linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), 
                                  linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
        background-size: 60px 60px;
        background-position: 0 0, 30px 30px;
    }*/
} 

.nestable-collapsed .nestable-list { 
    display: none; 
}

.nestable-dragel { 
	position: absolute; 
	pointer-events: none; 
	z-index: 9999; 
        
    > .nestable-item .nestable-handle { 
	margin-top: 0; 
    }
    
    .nestable-handle {
        -webkit-box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
                box-shadow: 2px 4px 6px 0 rgba(0,0,0,.1);
    }
    
}