.nav-tabs {
    margin-bottom: 30px;
}

@media(max-width: $screen-lg-max) {
    #tabProperties {
        .panel {
            margin: 0;
            border: none;
            @include box-shadow(none);
            
            .panel-body {
                padding: 0;
                border: none;
            }
        }
    }
}

@media(min-width: $screen-xl-min) {
    #tabProperties {
        display: block;
        margin-top: -32px;
        
        .panel-body {
            padding-bottom: 0;
        }
        
        label {
            float: none;
            width: 100%;
            margin-bottom: 5px;
            padding-top: 0;
            
            + div {
                float: none;
                width: 100%;
            }
        }
        
        .bootstrap-switch {
            position: absolute;
            bottom: 0; right: 15px;
        }
    }
    
    .no-tabs {
        #tabProperties {
            margin-top: 0;
        }
        
        .nav-tabs {
            display: none;
        }
    }
}