.uploader {
    
    .panel {
        position: relative;
        border-bottom: none;
        
        .panel-borders {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid $panel-default-border;
            z-index: 1;
        }
        
        .panel-heading,
        .panel-body,
        .panel-footer {
            position: relative;
            z-index: 2;
        }
        
        .panel-heading {
            .uploader-options {
                margin-left: 15px;
            }
        }
    }
    
    .uploader-total-process {
        display: none;
        margin: 7px 0;
    }
    
    .uploader-list {
        padding: 0;
        border-bottom: none;
    }
    
    .uploader-item {
        position: relative;
        float: left;
        width: 100%;
        padding: 15px;
        border-right: 1px solid $panel-default-border !important;
        border-bottom: 1px solid $panel-default-border !important;
        transition: background 240ms;
        cursor: grab;
        background: #fff;
        
        &.ui-sortable-helper {
            border: 1px solid $panel-default-border;
            cursor: grabbing;
        }
        
        figure {
            position: relative;
            margin-bottom: 15px;
            overflow: hidden;
            text-align: center;
            
            .fa {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid $panel-default-border;
                font-size: 55px;
                //color: $panel-default-border;
                
                &:before {
                    position: absolute;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            
            img {
                position: relative;
                max-width: 100%;
                z-index: 1;
            }
            
            figcaption {
                position: absolute;
                bottom: 0; left: 0;
                display: block;
                overflow: hidden;
                width: 100%;
                font-size: 12px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        
        .uploader-item-progress {
            display: none;
            
            .progress {
                margin-bottom: 0;
                
                .progress-bar {
                    width: 0;
                }
            }
            
            p {
                margin: 0;
            }
        }
        
        .uploader-item-info {
            width: 100%;
            
            textarea {
                margin-top: 15px;
            }
            
            textarea {
                height: 83px;
                resize: none;
            }
        }
        
        &.busy,
        &.error {
            .uploader-item-progress {
                display: block;
                
                .progress-bar {
                    @include progress-bar-variant($progress-bar-info-bg);
                }
            }
            
            .uploader-item-info,
            .uploader-item-buttons {
                display: none;
            }
        }
        
        &.error {
            background: $state-danger-bg;
            
            .uploader-item-progress {
                .progress-bar {
                    @include progress-bar-variant($progress-bar-danger-bg);
                }
                
                p {
                    margin-top: 5px;
                    color: $state-danger-text;
                    text-align: center;
                }
            }
        }
        
        &.success {
            background: $state-success-bg;
            
            .uploader-item-progress {
                .progress-bar {
                    @include progress-bar-variant($progress-bar-success-bg);
                }
            }
        }
    }
}

@media(min-width: $screen-sm-min) {
    .uploader {
        .uploader-list {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
        }
        
        .uploader-item {
            figure {
                padding-top: 75%;

                img {
                    position: absolute;
                    top: 50%; left: 50%;
                    max-height: 100%;
                    transform: translate(-50%, -50%);
                }
            }
            
            &.error,
            &.busy {
                .uploader-item-progress {
                    .progress {
                        margin-top: 21px;
                    }

                    p {
                        margin-top: 22px;
                    }
                }
            }
        }
    }
}

@media(min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .uploader {
        .uploader-item {
            width: 50%;
            
            &:nth-child(2n){
                border-right: none;
            }
        }
    }
}

@media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .uploader {
        .uploader-item {
            width: 33.333333%;
            
            &:nth-child(3n){
                border-right: none;
            }
        }
    }
}

@media(min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    .uploader {
        .uploader-item {
            width: 25%;
            
            &:nth-child(4n){
                border-right: none;
            }
        }
    }
}

@media(min-width: $screen-xl-min) {
    .uploader {
        .uploader-item {
            width: 20%;
            
            &:nth-child(5n){
                border-right: none;
            }
        }
    }
}