.link-selector {
    .input-group-addon {
        cursor: pointer;
    }
}

.link-selector-modal {
    
    .modal-header {
        button {
            width: 26px;
            height: 26px;
            margin-left: 5px;
        }
    }
    
    .alert {
        margin: 0;
    }
    
    .modal-body {
        overflow-y: auto;
        max-height: calc(100vh - 182px);
        padding: 0;
    }
    
    .nestable {
        margin-top: 0;
        padding: 15px 15px 10px 15px;
        
        .nestable-list {
            .nestable-empty .nestable-handle.disabled, 
            .nestable-item .nestable-handle.disabled, 
            .nestable-placeholder .nestable-handle.disabled {
                cursor: pointer !important;
            }
            
            .nestable-item {
                &.selected {
                    
                    > .nestable-handle {
                        background: $alert-success-bg;
                        border-color: $alert-success-border;

                        &:before {
                            position: absolute;
                            top: 12px; right: 15px;
                            font-size: 17px;
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: "\f058";
                        }
                        
                        > a:first-child {
                            color: $alert-success-text;
                        }

                        &:hover {
                             background: shade($alert-success-bg, 3%);
                        }
                    }
                }
            }
        } 
    }
}