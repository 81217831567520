.form-horizontal {
    label {
        text-align: left !important;
    }
}

.bootstrap-switch {
    input {
        display: none;
    }
}

.form-footer {
    margin-top: 15px;
    padding-top: 30px;
    border-top: 1px solid #ddd;
    @include clearfix();
}