* {
    outline: none !important;
}

body {
    overflow-x: hidden;
}

a {
    outline: none;
}

#wrapper {
    width: calc(100vw + #{$aside-width});
    transform: translate(-#{$aside-width}, 0);
    transition: transform 200ms ease-out;
    display: flex;
    
    &.show-nav {
        transform: translate(0, 0);
    }
    
    aside,
    #main {
        align-items: stretch;
    }
    
    #main {
        float: left;
        width: 100vw;
        padding-bottom: 40px;
        
        section {
            position: relative;
            padding: 30px 15px 15px 15px;
            background: $aside-background;
            
            .breadcrumb {
                display: none;
                margin: 0;
                padding-left: 0;
                padding-right: 0;
            }
            
            h1 {
                margin: 0;
                padding: 0;
                font-size: 32px;
            }
        }
        
        .alert {
            margin: 0;
        }
        
        > .container-fluid {
            padding-top: 40px;
        }
    }
}

@media(min-width: $screen-md-min) {
    #wrapper {
        width: 100vw;
        transform: translate(0, 0);
        
        #main {
            width: calc(100vw - #{$aside-width});
            
            section {
                .breadcrumb {
                    display: block;
                    position: absolute;
                    top: 34px; right: 15px;
                }
            }
        }
    }
}


@media(min-width: $screen-lg-min) {
    #wrapper {
        #main {
            padding-bottom: 40px;
            
            section {
                padding-left: 40px;
                padding-right: 40px;
                
                h1 {
                    left: 40px;
                }
                
                .breadcrumb {
                    right: 40px;
                }
            }
            
            > .alert {
                padding-left: 40px;
                padding-right: 40px;
            }
            
            .container-fluid {
                padding-left: 40px;
                padding-right: 40px;
            }
            
        }
    }
}