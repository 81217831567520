.headers {
    @extend .uploader;
    
    .headers-list {
        @extend .uploader-list;
    }
    
    .headers-item {
        @extend .uploader-item;

        figure {
            margin-bottom: 5px !important;
        }
        
        > input[type=text] {
            display: none;
        }
    }
}

.headers-modal {
    @extend .headers;
    
    .modal-header {
        .modal-title {
            margin-top: 5px;
        }

        .form-control {
            display: inline-block;
        }

        button {
            margin-left: 5px;
        }
    }
    
    .alert {
        margin: 0;
    }
    
    .modal-body {
        overflow-y: auto;
        max-height: calc(100vh - 182px);
    }
    
    .modal-footer {
        position: relative;
        top: -1px;
    }
    
    .headers-loader {
        width: 100%;
        height: auto;
        padding: 15px;
        text-align: center;
    }
    
    .headers-list {
        .headers-item {
            cursor: pointer;

            &.selected {
                @extend .bg-success;
            }
            
            > input[type=text] {
                display: block;
            }
            
            > .input-group {
                display: none;
            }
        }
    }
}

@media(min-width: $screen-sm-min) {
    .headers {
        figure {
            padding-top: 30% !important;
        }
    }
}

@media(min-width: $screen-lg-min) {
    
    .headers-modal {
        .modal-lg {
            width: 1200px;
        }
    }
}