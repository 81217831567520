$dropdown-background: #F8F8F8;

header {
    position: relative;
    height: 50px;
    background: #FF6600;
    
    > button,
    > .dropdown button,
    > a {
        display: block;
        min-width: 50px;
        height: 50px;
        line-height: 50px !important;
        margin: 0 !important;
        border: none;
        background: none;
        color: #fff;
        text-align: center;
        transition: background 140ms;
        
        &:hover {
            background: rgba(0, 0, 0, 0.1);
            text-decoration: none;
            color: #fff;
        }
        
        &.fa {
            font-size: 22px;
        }
    }
    
    .user {
        line-height: 50px !important;
        padding: 0 10px;
        
        i {
            position: relative;
            top: 2px;
            font-size: 22px;
        }
    }
    
    .dropdown {
        
        button[aria-expanded=true] {
            background: shade($dropdown-background, 5%);
            text-decoration: none;
            color: $text-color;
        }
        
        .dropdown-menu {
            top: 50px; right: -5px;
            min-width: 200px;
            margin: 0;
            padding: 0;
            background: shade($dropdown-background, 5%);
            border: 1px solid shade($dropdown-background, 10%);
            border-top: none;
            //@include box-shadow(0 2px 12px);
            border-radius: 0;
            
            li {
                &:not(last-child){
                    border-bottom: 1px solid shade($dropdown-background, 10%);
                }
                
                a {
                    padding: 10px 15px;
                    border-right: 5px solid shade($dropdown-background, 10%);
                    color: #777;
                    
                    &:hover {
                        background: shade($dropdown-background, 10%);
                        border-color: shade($dropdown-background, 20%);
                    }
                    
                    &.active {
                        border-color: $brand-primary;
                        
                        i {
                            color: $brand-primary;
                        }
                    }
                    
                    i {
                        position: relative;
                        top: 1px;
                        margin-right: 6px;
                        font-size: 17px;
                    }
                }
            }
        }
    }
    
    .logo {
        position: absolute;
        top: 10px; left: 50%;
        width: 120px;
        height: 32px;
        transform: translate(-60px, 0);
        background: rgba(0, 0, 0, 0) url("https://s3.eu-central-1.amazonaws.com/topsite-cms4-assets/img/logo_topsite.png");
        background-repeat: no-repeat;
        background-position: 0 -60px;
        background-size: 100% auto;
    }
}